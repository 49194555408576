/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export enum BaseRoutes {
  PARENT = "/forelder",
  STUDENT = "/elev",
  SELLER = "/pl",
}

export const RoutesConfig = {
  CONTACT_ME: {
    name: "Kontakt meg",
    path: "/kontakt-meg",
    component: () => import("@/views/onboarding/ContactMe.vue"),
  },
  CONTACT_ME_TARGET_GROUP: {
    name: "Kontakt meg",
    path: "/kontakt-meg/:targetGroup",
    component: () => import("@/views/onboarding/ContactMe.vue"),
  },
  STUDENT_FEEDBACK: {
    name: "Tilbakemelding for deltakere",
    path: "/t/s/:teamid/:uid",
    component: () => import("@/views/student/StudentFeedback.vue"),
  },
  PARENT_FEEDBACK: {
    name: "Tilbakemelding for foreldre",
    path: "/t/p/:teamid/:uid",
    component: () => import("@/views/customer/ParentFeedback.vue"),
  },
  GOD_MODE: {
    name: "God mode",
    path: "/god-mode/:uid",
    component: () => import("@/views/GodMode.vue"),
  },
  LOGIN: {
    name: "Logg inn",
    path: "/logg-inn",
    component: () => import("@/views/Login.vue"),
  },
  ORDER_TEAM: {
    name: "Team-bestilling",
    path: "/team-bestilling/:teamID",
    component: () => import("@/views/onboarding/AutomaticTeamOnboarding.vue"),
  },
  REGISTER_PL: {
    name: "Søk om å bli PL",
    path: "/bli-personlig-laerer",
    component: () => import("@/views/onboarding/sellers/RegisterPL.vue"),
  },
  REGISTER_PRO: {
    name: "Søk om å bli Pro",
    path: "/bli-pro",
    component: () => import("@/views/onboarding/sellers/RegisterPro.vue"),
  },
  COURSE_PICKER_FULL_PAGE: {
    name: "Velg tid",
    path: "/velg-tid/:courseID",
    component: () => import("@/views/onboarding/AvailableCoursePickerFullPage.vue"),
  },
  VIDEOS: {
    name: "Innhold",
    path: "/innhold",
    component: () => import("@/views/VideoLibrary.vue"),
  },
  SELLER_DASHBOARD: {
    name: "Oversikt",
    path: "/pl/",
    component: () => import("@/views/seller/SellerDashboard.vue"),
  },
  SELLER_FEEDBACK: {
    name: "Tilbakemelding for Pro",
    path: "/t/pro/:teamid/:uid",
    component: () => import("@/views/seller/SellerFeedback.vue"),
  },
  SELLER_LESSONS: {
    name: "Kalender",
    path: "/pl/kalender",
    component: () => import("@/views/seller/LessonsSeller.vue"),
  },
  SELLER_NEWS: {
    name: "Siste nytt",
    path: "/pl/siste-nytt",
    component: () => import("@/views/seller/News.vue"),
  },
  SELLER_PAYOUTS: {
    name: "Utbetalinger",
    path: "/pl/utbetalinger",
    component: () => import("@/views/seller/Payouts.vue"),
  },
  SELLER_PROFILE: {
    name: "Profil",
    path: "/pl/profil/:userId?",
    getPath(userId: string): string {
      return `/pl/profil/${userId}`;
    },
    component: () => import("@/views/Profile.vue"),
  },
  SELLER_REPORTS: {
    name: "Rapporter",
    path: "/pl/rapporter",
    component: () => import("@/views/Reports.vue"),
  },
  SELLER_RESOURCES: {
    name: "Ressurser",
    path: "/pl/ressurser",
    component: () => import("@/views/seller/Resources.vue"),
  },
  SELLER_SETTINGS: {
    name: "Innstillinger",
    path: "/pl/innstillinger",
    component: () => import("@/views/Settings.vue"),
  },
  CUSTOMER_DASHBOARD: {
    name: "Oversikt",
    path: "/forelder/",
    component: () => import("@/views/customer/CustomerDashboard.vue"),
  },
  CUSTOMER_DIPLOMAS: {
    name: "Diplomer",
    path: "/forelder/diplomer",
    component: () => import("@/views/student/Diplomas.vue"),
  },
  CUSTOMER_LESSONS: {
    name: "Kalender",
    path: "/forelder/kalender",
    component: () => import("@/views/customer/LessonsCustomer.vue"),
  },
  CUSTOMER_SETTINGS: {
    name: "Innstillinger",
    path: "/forelder/innstillinger",
    component: () => import("@/views/Settings.vue"),
  },
  CUSTOMER_PAYMENTS: {
    name: "Betaling",
    path: "/forelder/betaling/:status?",
    getPath(status?: "suksess" | "feil"): string {
      return `/forelder/betaling${status ? "/" + status : ""}`;
    },
    component: () => import("@/views/customer/Payment.vue"),
  },
  CUSTOMER_PRACTICAL_INFORMATION: {
    name: "Praktisk informasjon",
    path: "/forelder/praktisk-info",
    component: () => import("@/views/customer/PracticalInfo.vue"),
  },
  CUSTOMER_REPORTS: {
    name: "Rapporter",
    path: "/forelder/rapporter",
    component: () => import("@/views/Reports.vue"),
  },
  CUSTOMER_STUDENTS: {
    name: "Mine barn",
    path: "/forelder/mine-barn",
    component: () => import("@/views/customer/MyStudentsCustomer.vue"),
  },
  STUDENT_DASHBOARD: {
    name: "Oversikt",
    path: "/elev/",
    component: () => import("@/views/student/StudentDashboard.vue"),
  },
  STUDENT_CALENDAR: {
    name: "Kalender",
    path: "/elev/kalender",
    component: () => import("@/views/customer/LessonsCustomer.vue"),
  },
  STUDENT_DIPLOMAS: {
    name: "Diplomer",
    path: "/elev/diplomer",
    component: () => import("@/views/student/Diplomas.vue"),
  },
  STUDENT_SETTINGS: {
    name: "Innstillinger",
    path: "/elev/innstillinger",
    component: () => import("@/views/Settings.vue"),
  },
};
