




















































































import { filterArrayUniques } from "@/helpers/ArrayUtils";
import {
  getReadableLastTeamSessionDate,
  getReadableTeamTimeRange,
  getWeekNumberFromTimestamp,
} from "@/helpers/timeUtils";
import { useRouter } from "@/router";
import { RoutesConfig } from "@/router/Routes";
import analytics from "@/services/analytics";
import Learnlink from "@learnlink/interfaces";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "AvailableTeamSlots",
  props: {
    course: {
      type: Object as PropType<Learnlink.Course.default>,
      required: true,
    },
    teamCourseSessions: {
      type: Array as PropType<Learnlink.App.Onboarding.TeamCourseSession[]>,
      required: true,
    },
    timeUnit: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    platformLocations: {
      type: Array as PropType<Learnlink.PlatformSettings.Location[]>,
      required: true,
    }
  },
  setup(props) {
    const { navigate } = useRouter();

    function getTeamCourseSessionsByDateRange(dateRange: string, teamId: string) {
      return props.teamCourseSessions.filter((teamCourseSession) => {
        const existing = getReadableDateRange(teamCourseSession);

        if (!existing) return false;

        return dateRange === existing.date && teamId === teamCourseSession.team.ID;
      });
    }

    const readableDateRanges = computed(() =>
      props.teamCourseSessions
        .map(getReadableDateRange)
        .filter(filterArrayUniques)
    );

    function getReadableDateRange(teamCourseSession: Learnlink.App.Onboarding.TeamCourseSession) {
      return {
        date: getReadableLastTeamSessionDate(teamCourseSession.session.startTime, teamCourseSession.course),
        location: teamCourseSession.team.location,
        id: teamCourseSession.team.ID,
      };
    }

    function chooseTime(teamCourseSession: Learnlink.App.Onboarding.TeamCourseSession) {
      analytics.track("onboardingCoursePicked", {
        courseID: teamCourseSession.team.courseID,
      });
      navigate(
        RoutesConfig.ORDER_TEAM.path
          .replace(":teamID", `${teamCourseSession.team.ID}`),
      );
    }

    function capitalizeFirstLetter(string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function getLocationFromString(str: string) {
      return props.platformLocations.find((location) => location.ID === str);
    }

    return {
      chooseTime,
      getReadableTeamTimeRange,
      getTeamCourseSessionsByDateRange,
      getWeekNumberFromTimestamp,
      readableDateRanges,
      getLocationFromString,
      capitalizeFirstLetter
    };
  },
});
