


































































import { getTeamInOnboarding } from "@/api/teamService";
import { getTeamSessionsForTeam } from "@/api/teamSessionService";
import AutomaticOnboardingForExistingUser from "@/components/onboarding/AutomaticOnboardingForExistingUser.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { capitalize } from "@/helpers/stringUtils";
import { auth } from "@/services/firebase";
import Moment from "@/services/moment";
import { computed, defineComponent, onMounted, provide, reactive } from "@vue/composition-api";
import { createCustomerAutomaticOnboarding } from "@/api/customerService";
import { getCourse } from "@/api/courseService";
import { getBirthYearString, getReadableLastTeamSessionDate } from "@/helpers/timeUtils";
import { RoutesConfig } from "@/router/Routes";
import { useModule } from "@/store/hooks";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import analytics from "@/services/analytics";
import authModule from "@/store/modules/auth";
import AutomaticOnboardingForLoggedInUser from "@/components/onboarding/AutomaticOnboardingForLoggedInUser.vue";
import handleError, { sanitizeError } from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import rules from "@/helpers/formRules";
import StepperForm from "@/components/StepperForm.vue";
import axios from "axios";

export default defineComponent({
  name: "AutomaticTeamOnboarding",
  components: {
    LoadingSpinner,
    AutomaticOnboardingForExistingUser,
    AutomaticOnboardingForLoggedInUser,
    StepperForm,
  },
  setup() {
    const vm = reactive({
      customer: {
        courseID: "",
        email: "",
        name: "",
        phone: "",
        password: "",
      },
      completed: false,
      course: {} as Learnlink.Course.default & {
        price: number;
      },
      team: {} as Learnlink.Team.Team,
      teamSessions: [] as Learnlink.TeamSession.default[],
      userExists: false,
      error: null as {
        displayMessage: string,
        error: Error,
      } | null,
      loading: true
    });

    const {
      currentRoute,
      navigate,
    } = useRouter();
    const { state } = useModule(authModule);
    const notify = useNotifier();

    const price = computed(() => {
      if (!vm.course.price) return "...";

      const cookie = document.cookie.split(";").filter((c) => c.includes("price"));
      // eslint-disable-next-line no-console
      console.log(cookie);
      const testPrice = cookie.length ? cookie[0].split("=")[1] : undefined;
      const originalPrice = testPrice || vm.course.price;
      return vm.course.weeks > 1 ? originalPrice : "3 490";
    });
    const readableDateRange = computed(() =>
      vm.teamSessions.length &&
      capitalize(`${Moment.unix(vm.teamSessions[0].startTime).format("dddd")}er fra ${getReadableLastTeamSessionDate(vm.teamSessions[0].startTime, vm.course)}`),
    );

    const fields: Learnlink.App.Onboarding.OnboardingField[] = [
      {
        step: 1,
        name: "E-post (forelder)",
        prop: "email",
        rules: [rules.required],
        type: "text",
      },
      {
        step: 2,
        name: "Fullt navn (forelder)",
        prop: "name",
        rules: [rules.name],
        type: "text",
      },
      {
        step: 3,
        name: "Telefonnummer (forelder)",
        prop: "phone",
        rules: [rules.required],
        type: "text",
      },
    ];

    onMounted(async () => {
      vm.loading = true;
      try {
        [vm.team, vm.teamSessions] = await Promise.all([
          getTeamInOnboarding(currentRoute.value.params.teamID),
          getTeamSessionsForTeam(currentRoute.value.params.teamID),
        ]);
        vm.course = await getCourse(vm.team.courseID);
        vm.customer.courseID = vm.team.courseID;
        vm.loading = false;
      }
      catch (e) {
        vm.loading = false;
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 404) {
            vm.error = {
              displayMessage: "Kurset finnes ikke 😅",
              error: e,
            };
          }
          else {
            vm.error = {
              displayMessage: `Noe gikk galt 😭 Code: ${e.response?.status}`,
              error: e,
            };
          }
        }
        else {
          vm.error = {
            displayMessage: "Noe gikk galt 😭",
            error: new Error("unknown error"),
          };
        }
      }
    });

    const previousPage = computed(() =>
      RoutesConfig.COURSE_PICKER_FULL_PAGE.path.replace(":courseID", vm.customer.courseID),
    );

    async function submit() {
      try {
        vm.customer.password = vm.customer.phone;
        vm.customer.email = vm.customer.email.trim();

        const nameArray = vm.customer.name.trim().split(" ");
        const firstName = nameArray.length > 1 ? nameArray.slice(0, nameArray.length - 1).join(" ") : nameArray[0];
        const lastName = `${nameArray[nameArray.length - 1]}`;

        const team = await createCustomerAutomaticOnboarding({
          courseID: vm.customer.courseID,
          email: vm.customer.email,
          firstName,
          lastName,
          phone: vm.customer.phone,
          password: vm.customer.password,
        }, vm.team.ID);

        if (team) {
          await analytics.track("onboardingComplete", {
            course: vm.customer.courseID,
          });
          notify({
            title: "Gratulerer med plass på kurs! 🥳",
            message: "",
            type: "success",
          });
          vm.completed = true;
          if (window.location.hostname !== "localhost") {
            window.ORIBI && window.ORIBI.api("setUserEmail", vm.customer.email);
          }
          await auth.signInWithEmailAndPassword(vm.customer.email, vm.customer.password);
          navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
        }
        else {
          notify({
            title: "Noe gikk galt! 🤯",
            message: "Det ser ut til at noen kom deg i forkjøpet og at det ikke er noen plasser igjen på dette tidspunktet. 😱" +
              "Sjekk ut kurskatalogen vår og finn et nytt tidspunkt, eller ta kontakt med oss på support@learnlink.no eller på chat!",
            type: "error",
          });
        }
      }
      catch (e) {
        handleError(e);
        if (sanitizeError(e).code === 409) {
          notify({
            title: "E-posten er allerede i bruk! 🤯",
            message: "Benytt en annen e-post, eller logg inn.",
            type: "error",
          });
        }
        else {
          notify({
            title: "Noe gikk galt! 🤯",
            message: `Ta kontakt med support dersom problemet vedvarer. Feilmelding: "${sanitizeError(e).code} ${sanitizeError(e).message}"`,
            type: "error",
          });
        }
      }
    }

    provide("submit", submit);

    const courseTitle = computed(() => vm.course?.title?.split(" -")[0]);
    const courseLocation = computed(() => {
      if (vm.course.location === "physical" && vm.team.city !== "" && vm.team.city && vm.team.location !== "" && vm.team.location) {
        return `${vm.team.city} - ${vm.team.location}`;
      }
      return undefined;
    });
    const courseType = computed(() => vm.course?.title?.split(" -")[1]);
    const firstSessionStartTime = computed(() => {
      return vm.teamSessions.length ? Moment.unix(vm.teamSessions[0].startTime).format("dddd DD. MMMM HH:mm") : 0;
    });
    const firstSessionEndTime = computed(() => {
      return vm.teamSessions.length ? Moment.unix(vm.teamSessions[0].endTime).format("HH:mm") : 0;
    });
    const lastSessionDate = computed(() => {
      return vm.teamSessions.length ? Moment.unix(vm.teamSessions[vm.teamSessions.length - 1].startTime).format("dddd DD. MMMM") : 0;
    });
    const week = computed(() => vm.teamSessions.length ? Moment.unix(vm.teamSessions[0].startTime).week() : 0);
    const isCalander = computed(() => vm.course.location === "calendar");

    return {
      courseTitle,
      courseType,
      existingUser: state.isAuthorized,
      fields,
      firstSessionEndTime,
      firstSessionStartTime,
      getBirthYearString,
      lastSessionDate,
      previousPage,
      price,
      isCalander,
      rules,
      submit,
      readableDateRange,
      vm,
      week,
      courseLocation
    };
  },
});
